import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import omit from 'lodash.omit';
import Meta from '../components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {Headline, Paragraph} from '@components/typography';
import {usePageContext} from '@components/Page';
import CTA from '@components/CTA';
import {styled, Colors, Fonts, ThemeProvider} from '@styles';

interface Data {
  data: {
    file: {
      publicURL: string;
    };
  };
}

const Eyebrow = styled.div.withConfig({
  componentId: 'fourofourEybrow'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  text-transform: uppercase;
  font-size: ${19 / 16}em;
  line-height: ${23 / 19};
  letter-spacing: ${0.59 / 19}em;
  color: ${Colors.Blue};
  margin: 0.5em 0;
`;

const Container = styled(props => (
  <ResponsiveContainer {...omit(props, 'bgImage')} />
))<{bgImage: string}>`
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4em 2em 0;
  box-sizing: border-box;
  background-image: ${({bgImage}) => `url(${bgImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.wide {
    align-items: center;
    height: 70vh;
    padding: 2em;
  }
`;

const StyledCTA = styled(CTA).withConfig({
  componentId: 'fourofourCTA'
})`
  margin: 0.5em 0;
`;

const NotFoundPage = ({data}: Data): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  return (
    <Fragment>
      <Meta title="Not Found" description="Page not found!" />
      <ThemeProvider
        theme={{
          ctaFg: Colors.Blue,
          ctaBg: Colors.Transparent,
          ctaHoverFg: Colors.White,
          ctaHoverBg: Colors.Blue,
        }}
      >
        <Container bgImage={data.file.publicURL} as={FontScale}>
          <Eyebrow>404 ERROR</Eyebrow>
          <Headline>Talk about future forward.</Headline>
          <Paragraph>
            You’re trying to navigate to a page that doesn’t exist yet! Head
            home and try again.
          </Paragraph>
          <StyledCTA href="/">Go to Homepage</StyledCTA>
        </Container>
      </ThemeProvider>
    </Fragment>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    file(relativePath: {eq: "2U-Background-Light-Gray.svg"}) {
      publicURL
    }
  }
`;
